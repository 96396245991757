import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bookinfo from "../components/custom/bookinfo"
import Img from "gatsby-image"
import Card from "../components/bb/card"
// import { css } from "@emotion/react"



export default function Books({data}) {
  console.log(data)
  return (
    <Layout>
      <SEO title="Rae Fox, contemporary romance author of the Bliss Creek Series" />
      <h1>Books by Rae Fox</h1>
      <h2>The Bliss Creek Series</h2>
        <Bookinfo 
          id="what-might-have-been"
          img={<Img fluid={data.book1.fluid} />}
          title="Book 1: What Might Have Been"
          href="https://www.amazon.com/dp/B074SY7V3R/"
          summary={<p>Ten years ago, Gabe McKenzie tossed away the one chance with the love of his life and a decision he constantly feels remorse. He’s never gotten over Annie Dalton. Now , she’s back in Bliss Creek, Texas. He wants a second chance to win her back, but learns quickly that she doesn’t trust him with her heart. He isn’t giving up on their chance to be together again. When a stalker invades Annie’s home, Gabe vows to fight with every fabric of his being to protect her. Can he save the woman he loves more than life itself?
                  <br/><br/>Annie Dalton moved back from California to her hometown of Bliss Creek. Life events made the move inevitable. Transitioning is smooth until she runs into Gabe McKenzie, the man who broke her heart ten years ago. She tries desperately to ignore the chemistry between them, but her traitorous heart wants him too much. When items start to disappear out of her home, she realizes more than memories of the life she shared with her deceased husband have followed her to Texas. Frightened beyond reasoning, she turns to Gabe and learns that trust can be regained when you’re with the man of your dreams.</p>}
        />
        <Bookinfo 
          id="always-and-forever"
          img={<Img fluid={data.book2.fluid} />}
          title="Book 2: Always and Forever"
          href="https://www.amazon.com/dp/B075NLR5FH/"
          summary={<p>Mike Hampton’s secret love for Bella Knight led him to a night he’ll never forget. Still, Bella isn’t giving him the time of day and avoids him at every turn. He’s not about to give her up, especially now that she’s pregnant with his child It’s an unexpected event and a circumstance in which he’s not unhappy to find himself with Bella.
                  <br/><br/>His perseverance’s unhindered when Bella’s brother-in-law tries to legally take her child, born from a previous marriage. Mike will do anything in his power to ensure Bella’s safety and the well-being of her daughter and his unborn child.
                  <br/><br/>Several years ago, Bella Knight’s life changed dramatically when her husband dies overseas, leaving her a pregnant widow. Raising her child alone has never been a hardship with the support of her family and friends. She loves her life as a mother but admits she does get lonely as a woman. In a moment of emotional weakness and never dreaming it would be more than just a guilty pleasure, Bella succumbs to the growing attraction she feels for Bliss Creek’s sexy chief of police, Mike Hampton.
                  <br/><br/>Having one unbelievable night with him, Bella is now pregnant with his child. Feeling guilty for having emotions from someone other than her dead husband, Bella is fighting her growing love for Mike. He isn’t making it easy for her to keep him at an emotional distance. Nevertheless, with the threat of her daughter being taken away hanging over her head, she accepts the help of the one person she knows can help her. Can she turn to another man and not betray the memory of her husband?
                  </p>}
        />
        <Bookinfo 
          id="the-color-of-love"
          img={<Img fluid={data.book3.fluid} />}
          title="Book 3: The Color of Love"
          href="https://www.amazon.com/dp/B07BNV4TW9/"
          summary={<p>After ten years in special- forces, Zach Dalton moves back to his hometown of Bliss Creek, Texas. Joining a security agency, Zach’s life seemed to be falling into a normal pattern until he receives a call for help from a brother-in-arms. Zach can never say no to one of his teammates and agrees without hesitation.
                  <br/><br/>When he learns the name of the woman he’s vowed to protect, he damn near chokes on his promise. Emma Wells is the redheaded proprietor of the sandwich shop in town. She rubs him the wrong way . How in the hell is he going to keep his more negative emotions in check? Making matters worse, he is unexplainably attracted to her, but he couldn’t get past her red hair, even if she is as hot as a Texas sunset over the lake in summer.
                  <br/><br/>Hiding out in the small sleepy town of Bliss Creek, Emma Wells keeps pushing ever deeper the knowledge of her past life and all the dirty little secrets, and praying that her vow of silence will keep her safe. But, no secret can remain hidden forever, as Emma discovers when her brother calls to let her know her cover is blown. He promises to send someone to help keep her safe. Never expecting the man being sent to rescue her will be Zach Dalton , she wants to run the other way. For some reason, beyond her comprehension the man hates her. It leaves her wondering if she can trust him with her life. Swallowing her pride and emotions, she agrees to flee with Zach, knowing she may never come back to the town she fell in love with. Will Zach Dalton be the man to bring her back into the light and out of the shadows of her past?</p>}
        />
    </Layout>
  )
}

export const query = graphql`
  query {
    book2: imageSharp(fluid: {originalName: {eq: "Always_and_Forever_Rae_Fox_small.jpg"}}) {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
    book3: imageSharp(fluid: {originalName: {eq: "The_Color_of_Love_Rae_Fox_small.jpg"}}) {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
    book1: imageSharp(fluid: {originalName: {eq: "What_Might_Have_Been_Rae_Fox_small.jpg"}}) {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`