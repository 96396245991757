import React from "react"
import { css } from "@emotion/react"
import Img from "gatsby-image";
import Card from "../bb/card";
import Button from "../bb/button";

const custom_css = css`
    margin-bottom: 44px;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
    @media screen and (min-width: 500px){
        grid-template-columns: 1fr 2fr;
    }
    .left div{
        margin-top: 0px;
    }
    h3{
        margin-top: 0;
        /* margin-bottom: 0; */
    }
    h4{
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }
    .gatsby-image-wrapper{
        /* max-width: 300px; */
    }
`;

const button_css = css`
    padding: 20px 40px;
    background-color: var(--secondary);
`;
export default function Bookinfo(props) {
    return (
      <div css={custom_css} id={props.id}>
        <div className="left">
            <Card>
              {props.img}
            </Card>
        </div>
        <div id="right">
            <h3>{props.title}</h3>
            <Button customstyle={button_css} value="Buy on Amazon" href={props.href} />
            <h4>Synopsis</h4>
            <p>{props.summary}</p>
        </div>
      </div>
    )
  }